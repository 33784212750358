import React from "react";
import Button from "../../components/common/Button";
import Container from "../../layout/container";
const Contact = () => {
  return (
    <Container>
      <div className="flex flex-col justify-center items-center gap-6 w-full px-8 h-[70vh]">
        <h1 className="font-bold md:text-4xl">
          お問い合わせを受け付けました。
        </h1>
        <div className="text-center">
          <p>後ほど担当者よりご連絡いたします。</p>
        </div>

        <Button>ホームページに戻る</Button>
      </div>
    </Container>
  );
};
export default Contact;
